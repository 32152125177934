
.bar-title {
    width: 100%;
    position: relative;
}

.bar-title-btn {
    float:right;
    position: absolute;
    right:0;
    top: 1.6rem;
}

.bar-title-btn .btn {
    color: #0d92bd;
    font-weight: bold;
    border: 0;
    background: transparent;
    margin-right: 0.5rem;
}

.order-select-item {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 4px 10px;
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.order-select-item.selected {
    background: #dc630b;
    color: #fff;
}

.order-select-item-desc {
 display: inline-block;
}