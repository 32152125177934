.ReactModal__Overlay {
    z-index: 15;
    background-color: rgba(0, 0, 0, 0.45);
}
.btn-close-modal {
    float: right;
    background: transparent;
    border:0;
    color: #888;
}
.btn-close-modal:hover {
    color: #000;
}

.form-group {
    margin-bottom: 0.5rem;
}
.form-group label {
    width: 100%;
}

.price-label {
    border-radius: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    display: inline-block;
    margin-right: 10px;
    width: 100px !important;
    cursor: pointer;
}

.items-count-label {
    border-radius: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    display: inline-block;
    margin-right: 10px;
    width: 50px !important;
    cursor: pointer;
}

.items-count-label.field {
    width: 80px !important;
}

.custom-price-input {
    width: 100%;
    border:0;
    background: transparent;
}

.transport-type-label {
    padding: 5px 10px;
    border: 1px solid #ccc;
    display: inline-block;
    width: 33.33333% !important;
    cursor: pointer;
}


.badge {
    font-size: 13px;
}

.loading-comp-cont {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 80vh;
}

.tr-link {
    cursor: pointer;
}

.show-more-cont {
    color: #666;
    text-align: center;
    margin: 1rem 0 0 0;
    cursor: pointer;
    font-size: 0.9rem;
}

.input-search {
    width: 300px;
}

.red-tick {
    color: red;
}

.expenses-input-group {
    position: relative;
    display: block;
    max-width: 600px;
}

.dashboard-item-cont {
    border: 1px solid #ccc;
    margin: 1rem 1rem 0 0;
    border-radius: 10px;
    padding: 1rem;
}

.dashboard-badge {
    font-size: 1.4rem;
    font-weight: 500;
    margin:auto;
}

.search-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 9;
}

.search-results-cont {
    position: absolute;
    height: 300px;
    max-width: 850px;
    overflow-x: scroll;
    background: #fff;
    border: 1px solid #666;
    z-index: 10;
}

select.form-control {
    -webkit-appearance: auto !important;
    appearance: auto !important;
    -moz-appearance: auto !important;
}

.form-control.no-width {
    width: unset;
}

.order-source-1 {
    background: rgba(243, 186, 243, 0.24);
}

.order-delivery-date-passed {
    background: rgba(155, 0, 0, 0.24) !important;
}

.carpet-waiting-item {
    padding: 5px 10px;
    border: 1px solid #ccc;
    display: inline-block;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    cursor: pointer;
}

.carpet-waiting-item:hover {
    background: #f1f1f1;
}

.dashboard-big-btn-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    margin: 1rem 1rem 0 0;
    border-radius: 10px;
    padding: 1rem;
    color: #fff;
    cursor: pointer;
}

.dashboard-big-btn-cont-icon {
    font-size: 40px;
    display: block;
    padding: 0 0 10px 0;
}

.btn-add-service {
    width: 200px;
}

.badge-chart-website-orders {
    position: absolute;
    top:0;
    right:0
}

.collapse {
    padding-left:1rem;
}
.list-page-title {
    margin-top:1rem;
}

.btn-directions {
    margin:0.75rem 0 0 0;
    display: block;
}

.cursor-pointer {
    cursor: pointer;
    user-select: none;
}


@media only screen and (min-width: 600px) {
    .ReactModal__Content {
        min-width: 600px;
        max-width: 600px;
    }

    .list-page-title {
        margin-top:3rem;
    }

    .list-page-table {
        margin-top:3rem;
    }


}

@media only screen and (max-width: 600px) {
    .ReactModal__Content {
        height: 100%;
        width: 100%;
    }

    .input-search {
        width: 80vw;
        margin-bottom: 0.5rem;
        position: absolute;
        top: 3px;
        right:0;
        background: transparent;
        height: 3rem;
        color: #fff;
        border:0
    }

    .input-search:focus {
        background: transparent;
        color: #fff;
    }

    .input-search::placeholder {
        color: #f1f1f1;
    }

    .small-modal-btns {
        margin-top: 4rem;
    }

    .transport-type-label {
        width: 100% !important;
    }

    .items-count-label {
        width: 70px !important;
        margin-bottom:5px;
    }

    .dashboard-search-cont {
        height: 0px;
        overflow: hidden;
    }

    .dashboard-search-cont h2 {
        display: none;
    }

    .dashboard-search-cont .dashboard-item-cont {
        height: 0px;
    }

    .search-results-cont {
        position: absolute;
        top:60px;
        left:0;
        height: 80vh;
        width: 100vw;
    }

    .search-result-add-form {
        top: 140px;
        width: 94vw;
    }

    .table-measure>:not(caption)>*>* {
        padding: 0.5rem 0;
        border:0;
    }
}
