.pv-container {
    max-width: 80%;
    margin: auto;
    margin-top: 3rem;
}

.pv-container h2 {
    font-weight: bold;
}

.pv-date {
    font-size: 1.2rem;
    font-weight: bold;
}




.bon-item {
    border: 1px solid #000;
    padding: 1rem 2rem;
    margin: 1rem 1rem 3rem 1rem;
}

.bon-item h4, .bon-item h5 {
    font-weight: bold;
}

.bon-item h4 {
    font-size: 1.1rem;
}